@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');

/* Colors from style guide */
:root {
    --black: #000;
    --blue1: #006787;
    --blue2: #00516A;
    --blue3: #3366cc;
    --blue4: #2556b8;
    --brown: #683000;
    --lightBrown1: #9D7050;
    --lightBrown2: #855D42;
    --darkGrey1: #4d4d4d;
    --darkGrey2: #716A68;
    --darkGrey3: #97918f;
    --grey1: #FCFCFC;
    --grey2: #F9F9F9;
    --grey3: #F5F5F5;
    --grey4: #EFEFEF;
    --grey5: #E5E5E5;
    --grey6: #C7C7C7;
    --orange1: #ffe2c4;
    --orange2: #fbad6b;
    --orange3: #F79544;
    --orange4: #D66200;
    --orange5: #BD5700;
    --vipYellow1: #EEC228;
    --green1: #156a1e;
    --green2: #115517;
    --lightGreen0: #f2faee;
    --lightGreen1: #D3E2CB;
    --lightGreen2: #b2d2a1;
    --lightGreen3: #60A33D;
    --lightGreen4: #469322;
    --red1: #990031;
    --lightRed1: #d94343;
    --lightRed2: #b52a2a;
    --teal1: #00bac9;

    --boxShadow: 0 5px 15px 1px rgba(0, 0, 0, .1);

    --smallLabel: .8rem;
}

a.underline {
    text-decoration: underline;
}

/* This declaration is extremely intrusive and makes other things hard to style, because it targets literally every single element on the entire website. TODO: Deprecate it entirely. */
* {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

a {
    font-size: inherit;
    font-weight: 500;
    text-decoration: none;
}

body {
    font-size: 2vw;
    font-weight: 600;
    margin: 0;
    overflow-x: hidden;
}

select {
    border: 2px solid #E5E5E5;
    border-radius: 1000px;
    font-size: .75em;
    font-weight: 500;
    padding: .25em;
}
  
select:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .05);
}

select:focus-visible {
    outline: none;
}

option {
    font-size: 14px;
    font-weight: 500;
    padding: 2px;
}

p { 
    font-size: 2vw;;
}

h1{
    text-align: center;
    font-size: 5vw;
    font-weight: bold;
}

/* accordion-collapse is a class name added by react-bootstrap to the part of the accordion that shows/hides on click */
.accordion-collapse {
    background-color: #E5EEF0;
}

.accordion-collapse:hover {
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .1);
}

/* expandOuter class used for accordion item container on book and home */
.expandOuter {
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: var(--blue1);
}

.expandOuter:last-of-type {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: var(--blue1);
}

/* General button styles - should be componentized */
.genButton {
    border-radius: 1000px;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    padding: .75em 1.25em;
    text-align: center;
    transition: all .2s ease;
    width: max-content;
    min-width: 90px;
}

/* Styles for "Buy Pro" button - should probably be componentitzed */
.genButton.goVIPButton {
    background: linear-gradient(3deg,#eec228 60%,#f3cd46 0,#f3cd46 92%,#eec228);
    border-color: #eec228;
    color: #683000;
    font-size: 16px;
    font-weight: 600;
}

/* Styles for study button - again, should probably be componentized */
.genButton.studyButton {
    border-color: #006787;
    background-color: #006787;
    font-size: 16px;
    padding: .75em 1.25em;
}

.genButton.studyButton:hover {
    background-color: #00516A;
    color: white;
    cursor: pointer;
}

.genButton.studyButton svg {
    margin-right: 10px;
}

.modal-content {
    background-color: white;
    border: none;
}

.modal-content .modal-body {
    font-size: 14px;
    font-weight: 500;
}

.modal-header {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.btn.btn-primary { 
    background: transparent;
    /* border: none; */
    border: 2px solid black;
    color: black;
    margin: 0;
    padding: 0;
}

.btn:not(:disabled):not(.disabled) {
    background: transparent;
    color: black;
}

.btn-primary:disabled { 
    background-color: transparent !important;
    border: none;
}

button.buttonFooter.btn.btn-primary{
    background-color: #F7AD60;
    font-family: "Georgia", serif;
    border: none;
    color: black;
    padding: 6px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 2.5vw;
    border-radius: 10px;
}

.header {
    line-height: 24px;
    font-weight: 600;
    background-color: #F7AD60;
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    z-index: 20;
    padding: 0.5em;
    position: fixed;
    width:100%;
}

.header a {
    flex-grow: 1;
    text-align: left;
    font-size: 3vw;
}

p.headerWord { 
    margin-bottom: 0;
    top: 50%; 
    bottom: 50%;
    font-size: 1.3em;
}

.header span {
    flex-grow: 1;
    text-align: right;
    font-size:.6em;
}

p.headerVersion {
    font-size: 1.3em;
    margin: 0;
}

p.headerLogOutButton {
    color: white;
    cursor: default;
    font-size: 2em;
    margin: 0;
    margin-top: .15em;
}
span.headerLogOutButton {
    color: white;
    cursor: pointer;
    font-size: 1em;
    margin: 0;
    margin-top: .15em;
}

.header-vip-reminder-text{
    margin: 0;
    padding: .5em;
    font-size: 10px;
    background-color: yellow;
    border-radius: .7em;
    box-shadow: 3px 3px 2px 1px rgb(0 0 255 / 20%);
    color: #2b2b2b;
    font-weight: 700;
}

button.vipHeaderButton.btn.btn-primary {
    flex-grow: 1;
    color: #F7AD60;
    display: inline-block;
    font-size: 3vw;
}

button.headerButton.btn.btn-primary{ 
    background-color: #c1f3f7;
    font-weight: 900;
    padding: 0.5em;
    font-size: 1em;
}

.timezone { 
    margin: auto;
    padding: 1%;
    text-align: center;
}

.timezone .select {
    margin: auto;
}
button.blueButton.btn.btn-primary { 
    background-color: #c1f3f7;
    color: black;
    padding: 4px 8px;
}
button.grayButton.btn.btn-primary {
    background-color: gray;
    color: black;
    padding: 4px 8px;
}

button.close { 
    padding: 0px;
    margin: 0px;
}

div.modal-content { 
    width: 70vw; 
    margin: auto;
}

.logo {
    width: 10em;
    margin-left: auto;
    margin-right: auto;
}

.modal-body p {
    font-size: 14px;
    text-align: left;
}

div.modal-header {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 5px;
}

div.modal-header > div {
    font-size: 16px;
    font-weight: 600;
    margin: 0.25em auto;
}

.modal-header .btn-close {
    margin: 0;
}

div.placementOptions > label.btn-primary{
    font-family: "Georgia", serif;
    font-weight: 800;
}

.version {
    font-size: 0.5em;
    margin: 0;
}

@media screen and (min-width: 480px) {
    option {
        font-size: 16px;
    }

    .modal-content .modal-body {
        font-size: 16px;
    }
}

@media screen and (min-width: 601px) {
    button.headerButton.btn.btn-primary {
        font-size: 20px;
        padding: 0.2em;
    }

    button.vipHeaderButton.btn.btn-primary {
        font-size: 18px;
    }

    .header a {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) { 
    .header{ 
        font-size: 4vw;
        padding: 1em;
    }

    p.headerVersion {
        text-align: right;
        font-size: 1em;
    }

    .header-vip-reminder-text{
        font-size: 10px;
        line-height: 12px;
    }

    p.headerLogOutButton {
        text-align: right;
        font-size: 1.5em;
    }
}

@media screen and (min-width: 768px) {
    .header-vip-reminder-text{
        font-size: 14px;
    }
}

@media screen and (max-width: 1000px) {
    .header-vip-reminder-text{
        width: 50%;
    }
}
