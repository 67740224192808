  /* Version Controls */
  .version-history-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .0rem;
    font-size: 14px; /* Set the font size for the entire container */
  }
  
  h2.version-history-label {
    text-align: center;

    font-size: 14px;
    font-weight: 500;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  
  .version-details-container * {
    width: 100%;
    max-width: 500px;
    padding: 0 0.5rem;
    text-align: left;
  }
  
  .details-text {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  
  .version-entry-template .title {
    font-size: 14px;
    font-weight: 500;
  }
  
  .changes-list {
    list-style-type: disc;
    margin-left: 1.5em;
  }
  
  
  .change-item {
    margin: 0.25em 0;
    font-size: 14px;
    font-weight: normal;
   }
  
  .version-change-sub-list {
    padding-left: 20px;  /* Increases the indentation of the entire list */
  }
  
  
  .spacing-div {
    height: 1rem;
    width: 100%;
  }

  .version-details-container {
    font-size: 14px; /* Ensure the font size is consistent within the details container */
  }
  
  .version-entry-template {
    font-size: 14px; /* Apply the font size to each version entry */
  }
  
