.loadingComponent {
    align-items: center;
    color: #D66200;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    place-content: center;
}

.loadingSpinner svg {
    margin-top: -.6em;
}

.loadingComponent * {
    color: #D66200;
    font-size: 32px;
    font-weight: 800;
    margin: 0;
}

.loadingComponent div {
    margin-left: .5em;
}

@media only screen and (min-width: 768px) {
    .loadingComponent  * {
        font-size: 48px;
    }
}