// Styling for hamburger button
// Only rendered at 700px (media query width below)
.nav__burger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2em;
    height: 2em;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  
    &:focus {
      outline: none;
    }
  
    div {
      width: 100%;
      height: 0.25em;
      background: #000;
      border-radius: 5px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
  
      // Starter styles for child divs (menu closed state)
      &:first-child {
        margin-left: 0;
        transform: rotate(0);
        width: 100%;
      }
  
      &:nth-child(2) {
        opacity: 1;
        transform: translateX(0);
      }
  
      &:nth-child(3) {
        margin-left: 0;
        transform: rotate(0);
        width: 100%;
      }
  
      // Transitioned styles for child divs (menu open state)
      &.open {
        &:first-child {
          margin-left: 0.25em;
          transform: rotate(45deg);
          width: 2.035em;
        }
    
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(20px);
        }
    
        &:nth-child(3) {
          margin-left: 0.25em;
          transform: rotate(-45deg);
          width: 2em;
        }
      }
    }
  }
  