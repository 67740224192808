.browserBlockerModal {
    max-width: 570px;
}

div.modal-dialog-centered {
    align-items: baseline;
}

.modal-dialog {
    margin-top: 100px;
}

.browserBlockerModal {

    div.modal-header {
        border: none;
    }
    
    div.modal-content {
        min-width: 300px;
        padding: .75em;
    }

    div.supportInfo {
        text-align: left;
    }

    div.bbHeader {
        color: var(--orange4);
        font-size: 18px;
        font-weight: 700;
        text-align: left;
        margin-bottom: .25em;
        margin-top: -.25em;
    }

    div.bbHeader img {
        height: 1.25em;
        margin-right: .25em;
        margin-top: -.25em;
        width: auto;
    }

    a.downloadRedirectButton {
        background-color: var(--blue1);
        border-radius: 1000px;
        color: white;
        display: flex;
        font-size: 15px;
        font-weight: 600;
        margin: .75em auto;
        min-width: 90px;
        padding: .75em 1.25em;
        text-align: center;
        transition: all .2s ease;
        width: fit-content;
    }

    a.downloadRedirectButton:hover {
        background-color: var(--blue2);
    }

    a.emailLink {
        color: var(--blue1);
        font-weight: 700
    }
}
