.errorContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: center;
    margin: auto;
    width: 80%;
}

.errorHeader {
    align-items: center;
    display: flex;
    font-weight: 800;
}

.errorTitle {
    color: #D66200;
    font-size: 32px;
    margin-left: 10px;
}

.errorQuokka {
    height: 40px;
}

.errorMessageMain {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 20px;
    padding-top: 15px;
    text-align: left;
}

.bookErrorButton:hover {
    background-color: #BD5700;
    padding: .75em 1.25em;
}

.bookErrorButton {    
    background-color: #D66200;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: .75em 1.25em;
    text-align: center;
    transition: all .2s ease;
}

.errorBackground {
    height: 120%;
}

.errorBackgroundContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: -1;
}

.centerMessage {
    max-width: 600px;
}

@media only screen and (min-width: 768px) {
    .errorQuokka {
        height: 48px;
    }

    .errorTitle {
        font-size: 40px;
    }

    .errorMessageMain, .bookErrorButton {
        font-size: 24px;
    }

    .errorBackground {
        height: 150%;
    }
}

@media only screen and (min-width: 992px) {
    .errorContainer:not(.centerMessage) {
        max-width: 600px;;
        width: 60vw;
    }

    .errorQuokka {
        height: 80px;
    }

    .errorTitle {
        font-size: 60px;
    }

    .errorMessageMain {
        font-size: 24px;
    }

    .errorBackground {
        height: 100%;
    }
}