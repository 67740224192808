/*reverting = MCD nav bar adjustments for breakpoints that were ugly.*/
/*added back = Janna recommendations to revert and try different*/
/* CONTAINER FOR TOP STICKY NAVBAR */
.navbarMainheaderContainer {
    align-items: center;
    background-color: #fafafa;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, .1); /*added back*/
    display: flex;
    /*reverting height: 50px;*/
    height: 38px; /*added back*/
    justify-content: center;
    width:100%;
    z-index: 20;

    /* sticky vocab header dependent on height value */
    position: sticky;
    top: 0;
}

/* TOP STICKY BAR OF NAVBAR */
.navbarMainheader {
    background-color: #fafafa;
    /*reverting box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, .1);*/
    display: flex;
    font-size: 14px;
    height: 38px;
    /*reverting max-width: 730px;*/
    max-width: 740px; /*added back*/
    /*reverting padding: 2em .01em;*/
    padding: 1.25em .5em; /*added back*/
    width: 100%;
    
    justify-content: space-around;
    align-items: center;
}

.hideMenu {
    color: #fafafa!important;
    cursor: default!important;
}

.hideReturnLinkOuter {
    color: #fafafa!important;
    cursor: default!important;
}

/* BOTTOM NON-STICKY BAR OF NAVBAR */
.navbarSubheader {
    background-color: #fafafa;
    /*reverting box-shadow: -2px 3px 2px -2px rgba(0, 0, 0, .1);*/
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, .1); /*added back*/
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    /*reverting margin-bottom: -5px;*/
    /*reverting padding: .85em .5em;*/
    padding: .4em .5em; /*added back*/
    position: relative;
    white-space: nowrap;
}

.hideNavBarSubheader {
    display: none;
}

/* TOP STICKY BAR CHILDREN */
.returnLinkOuter, .menuContainer { 
    width: 90px; /* these values must be same to center beepboop logo*/
}

/* TOP STICKY BAR CHILDREN - RETURN */
.returnLink {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    margin-left: 8px;
    text-align: left;
    font-weight: 600;
}

.returnLinkOuter {
    /*reverting margin-top: 8px;
    margin-left: 0px;*/
    align-items: center;
    line-height: 1.15;
    color: black;
    display: flex;
    cursor: pointer;
    /*reverting padding-left: 10px;*/
}

/* TOP STICKY BAR CHILDREN - CENTER CONTENT*/
.versionWrapper {
    padding-top: 30px;
    bottom:10px;
    width:100%;
}

.versionString {
    color: var(--blue2);
    text-decoration: underline;
    font-weight: 400;
    font-size: 12px;
}

.centerText {
    align-self: center;
    position: relative;
    padding-left: 5px; /* necessary to horizontally center the beepboop logo */
    /*reverting margin-top: 10px;*/
}

.textLogo {
    margin: auto;
    height: 30px;
}

/* TOP STICKY BAR CHILDREN - MENU */
.menuContainer {
    font-size: 10px;
    padding-left: 60px;
    margin-right: 3px; /*added back*/
    margin-bottom: -2px;
}

.menuContainerHover {
    align-self: flex-end;
    width: fit-content;
}

.menuContainerHover:hover {
    cursor: pointer;
}

.menuContainer button {
    float: right;
    margin-bottom: -3px;
}

.hamburgerAndText {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

/* EXPANDED MENU */
.expandingMenuContainer {
    position: absolute;
    top: 40px;
    background-color: #FAFAFA;
    height: 0;
    transition: .2s all ease;
    width: 100%;

    /* z-index is 5 more than main mobile navbar */
    z-index: 25;
}

/* OPEN EXPANDED MENU */
.openMenu {
    border-top: 1px solid #EAE9E9;
    height: 100%;
    padding-bottom: 45px;
    position: fixed;
}

/* CONTENTS OF MENU */
.menuContents {
    align-items: center;
    display: none;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
    height: 100%;
    justify-content: flex-start;
    overflow-y: scroll;
    width: 100%;
}

/* CONTENTS WHEN MENU IS OPEN */
.openContents {
    display: flex;
}

.logoutText {
    color: var(--orange4);
    font-weight: 700;
}

.logoutText:hover {
    color: var(--orange5);
}

.menuGoVIPContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}

.menuContentsLink, 
.logoutText {
    text-align: center;
}

.menuContents .menuContentsLink {
    color: black;
    padding: .7em;
    transition: .2s all ease;
    width: 100%;
}

.menuContents .menuContentsLink:not(:first-child) {
    border-top: 1px solid #EAE9E9;
}

.menuContents {
    background-color: #fafafa;
    cursor: default;
}

/* BOTTOM OF PAGES, BENEATH CHILDREN */
.verticalSpacer {
    height: 25px;
}
.bottomVIPContainer {
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--blue1);
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .1);
    max-width: 650px;
    margin: auto;
    padding: 0px 5px 5px 5px;
}

.bottomGoVIPContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}

.bottomGoVIPContents {
    font-size: 15px;
    font-weight: 600;
    color: #683000;
    font-style: italic;
}

.bottomQuoteContainer {
    color: var(--blue1);
    font-size: 17px;
    font-weight: 500;
    font-style: italic;
    max-width: 650px;
    text-align: center;
    margin: auto;
    padding: 0px 5px 0px 5px;
}

.bottomReviewLink {
    padding-top: 7px;
    text-decoration: underline;
    font-style: normal;
}

/* TOP OF PAGE, IMMEDIATELY BELOW NAV BARS */
.announcementBarSubheader {
    justify-content: center;
    margin: auto;
    padding: .4em .5em .1em 1em;
    align-items: center;
    max-width: 650px;
}

.announcementMessageContainer {
    background-color: #E5EEF0;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--blue1);
    // box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .1);
    color: var(--blue1);
    display: inline-block;
    font-size:16px;
    margin: auto;
    padding: .4em 1em .4em 1em;
    width: fit-content;
}

.announcementMessageContainer:hover {
    background-color: #BFD9E1;
    color: var(--blue2);
}

.announcementContentsLink p {
    font-size: 16px;
    margin: 0;
}

.talkkaMegaphoneAnnounce {
    height: 2.3em;
    width: auto;
}

.flip {
    transform: scaleX(-1);
}

.announcementContentsLink {
    color: var(--orange4);
    text-decoration: underline;
}

.announcementMessageContainer_blink {
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--blue1);
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .1);
    padding: .4em 1em .4em 1em;
    color: var(--blue1);
    background-color: lightgreen;
    display: flex;
    font-size:16px;
    width: fit-content;
    margin: auto;
    align-items: center;
}

.blink_talkkaround {
    -webkit-animation-name: blinker_talkkaround;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    
    -moz-animation-name: blinker_talkkaround;
    -moz-animation-duration: 3s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    
    animation-name: blinker_talkkaround;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.campaignAnnouncementBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--blue3);
    color: white;
    width: 100%;
}
.campaignAnnouncementBar {
    font-size: .8rem;
    padding: .4rem 1rem;
    max-width: 600px;
}

@-moz-keyframes blinker_talkkaround {  
    0% { 
        opacity: 1.0;
    }
    50% { 
        opacity: 0.7; 
    }
    100% { 
        opacity: 1.0; 
    }
}

@-webkit-keyframes blinker_talkkaround {  
    0% { 
        opacity: 1.0; 
    }
    50% { 
        opacity: 0.7; 
    }
    100% { 
        opacity: 1.0; 
    }
}

@keyframes blinker_talkkaround {  
    0% { 
        opacity: 1.0; 
    }
    50% { 
        opacity: 0.7; 
    }
    100% { 
        opacity: 1.0; 
    }
}

@media screen and (max-width: 320px) {
    .menuContainer {
        padding-left: 40px;
    }

    .centerText {
        padding-left: 5px;
    }
}

@media screen and (max-width: 480px) {
    .menuContainer {
        padding-left: 45px;
    }

    .textLogo {
        height: 25px;
    }

    .returnLink {
        font-size: 11px;
        margin-left: 4px;
    }
}

@media screen and (min-width: 480px) {
    .centerText {
        font-size: 16px;
    }

    .navbarMainheaderContainer { /*added back*/
        /* sticky vocab header dependent on height value */
        height: 45px;
        padding: .5em .35em;
    }

    /* TOP STICKY BAR CHILDREN - MENU */
    .menuContainer {
        font-size: 11px;
        padding-left: 75px;
    }

    /* TOP STICKY BAR CHILDREN - EXPANDED MENU */
    .expandingMenuContainer {
        top: 45px;
    }
}

@media only screen and (min-width: 768px) {
    .centerText {
        font-size: 18px;
    }

    .navbarMainheaderContainer { /*added back*/
        /* sticky vocab header dependent on height value */
        height: 50px;
    }

    .returnLink {
        font-size: 14px;
    }

    /* TOP STICKY BAR CHILDREN - MENU */
    .menuContainer {
        font-size: 12px;
        padding-left: 60px;
    }

    /* TOP STICKY BAR CHILDREN - EXPANDED MENU */
    .expandingMenuContainer {
        top: 50px;
    }
} 

