/* Most styles in ErrorFallback are implemented from classes created for ErrorMessage. Those declarations can be found in src/components/ErrorMessage/errormessage.css */

.errorFallback {
  padding-top: 2rem;
}

.errorFallback__homeLink {
  /* POSITION */
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.errorFallback__homeLink a {
  font-weight: 600;
}

.errorContainer.fallbackMessage {
  height: unset;
}

.errorFallback__button {
  background: var(--orange5);
  border: 0;
  border-radius: 1000px;
  box-shadow: none;
  color: #fff;
  display: block;
  font-weight: 600;
  padding: 0.75em 1.25em;
}

@media only screen and (max-width: 825px) {
  .errorFallback {
    padding-top: 3rem;
  }

  .errorFallback__homeLink a {
    font-size: 0.9rem;
  }
}
